<template>
  <div>
    <left-and-right>
      <div slot="leftDiv">
        <h4>陕西西咸新区服贸协会协会简介</h4>
        <h5 style="margin-bottom: 50px;font-weight: 500">Organizational structure</h5>
        <p>
          陕西西咸新区服务贸易协会Shaanxi Xixian New Aera Association of Trade in
          Services(英文缩写：“SXXXATIS”)是经陕西省西咸新区改革创新发展局同意、陕西省西咸新区行政审批与政务服务局核准登记注册（统一社会信用代码：51610100MJU925023A），是西北首家、具有独立法人地位的非营利社会团体。
        </p>
        <p>
          陕西西咸新区服务贸易协会现有单位会员40个，其中理事会员单位5个，监事会员单位3个。陕西零工保信息科技有限公司等10家企业为龙头企业。发展会员包括单位会员和个人会员，主要为西咸新区境内各行业热衷于公益事业的单位和个人。其中团体发起人为10家单位会员。其他会员主要分布在西咸新区所属各新城，包括空港新城、沣东新城、秦汉新城、沣西新城、泾河新城，共计5个新城，满足覆盖新区范围内4个以上新城要求。拟于2021年底收纳更多省内从事服务贸易业务的企业，会员数发展到100家。</p>
        <div class="slogan">
          <h4>服务共享，聚智共赢</h4>
          <p>为会员创造价值，推动行业自律</p>
        </div>
        <h4><span></span>协会宗旨</h4>
        <p>
          遵守宪法、法律、法规和国家政策，践行社会主义核心价值观,遵守社会道德风尚。协会在内旨在搭建政府与企业间沟通桥梁，反映企业诉求；深入研究服务贸易的发展规律，为政府政策和企业制定发展战略提供有效依据；宣传国家及西安市鼓励服务贸易发展的各项优惠政策并协助企业充分利用；组织人才、技术、管理、法规等培训；协助政府主管部门，规范服务贸易行为，进行行业自律，防止无序竞争等。在外旨在积极对外宣传西安企业的实力；为企业搭建国际交流与合作平台，为企业开拓国际市场创造条件；与各国服务业协会合作，致力于减少国际间服务贸易壁垒，促进服务贸易自由化发展。认真贯彻落实国家服务贸易相关政策，树立协调、绿色、开放、共享的发展理念，以推动陕西西咸新区服务贸易发展，同时做好服务贸易数据统计工作，为政府决策提供相应的信息支撑。协助政府制定和完善服务贸易法规体系；在全球范围内推广中国服务品牌，提升中国服务国际影响力。
        </p>
        <h4><span></span>成立背景</h4>
        <p>
          服务贸易发展战略本身不仅是在优化我国产业结构，也是我国经济发展中重点突破的核心发展方向，又是我国“一带一路”发展战略的重要支柱，更是助力我国企业做大做强的必要途径。因此，成立一个有助于我省企业“走出去”发展的社团组织，在政策层面上是不存在阻碍的，甚至是大力支持的方向。总之，成立“陕西省西咸新区服务贸易协会”完全可行。
        </p>
        <p>
          国务院近日批复的28个全面深化服务贸易创新发展试点，陕西西安与西咸新区均名列其中。西咸新区承担着建设我国向西开放重要枢纽，打造“一带一路”服务贸易重要基地、西部基地服务贸易增长引擎的重要任务，以服务外包、服务贸易以及高端制造业和技术研发环节转移为主的新一轮世界产业结构调整正在兴起，为中国发展面向国际市场的现代服务业带来新的机遇。成为全国服务贸易创新发展的新标杆和西部地区新的经济增长极。
        </p>
        <h4><span></span>协会愿景</h4>
        <p>服务共享，聚智共赢。</p>
        <h4><span></span>协会使命</h4>
        <p>为会员创造价值，推动行业自律。</p>
        <h4><span></span>业务范围</h4>
        <p>1.建立服务贸易各领域民间自律、协调机制，实现行业
          间资源共享、相互融合的发展环境；</p>
        <p>2.加强服务贸易领域研究，掌握国际服务贸易发展动态，为政府和企业制定发展战略提供依据；</p>
        <p>3.加强国际交流与合作。组织企业参加国际服务贸易展览会、交易会、研讨会及境外商务考察等，为企业开拓国际市场、加强国际交流创造条件；</p>
        <p>4.组织国内、国际范围的、力求提升中国服务贸易专业化水平的培训项目；</p>
        <p>5.拓展对外渠道，加强对外交流管理，与境内外相关国际组织建立合作关系和定期交流机制，为企业搭建国际交流与合作平台；为境外企业提供投资环境、投资政策、法律咨询、决策顾问服务，并做前期市场调研。</p>
        <p>6.建设服务贸易公共信息服务平台，使之成为中国服务贸易海外推介窗口，并提供行业、市场等公共信息服务；</p>
        <p>7.创办内部交流刊物，发布服务贸易领域研究报告；</p>
        <p>8.协助商务部门对服务贸易示范区及外包基地进行有效管理；</p>
        <p>9.积极向政府部门反映行业和企业的愿望和要求，承办政府部门交办的各项任务；</p>
        <p>10、塑造、推广中国服务贸易品牌形象；</p>
        <p>11.组织服务贸易相关人才、技术、管理、法规等的沟通交流；</p>
        <p>12.接受企业委托，为企业提供法律、政策、市场等咨询服务；</p>
        <p>13.办理其他与本会宗旨有关的事项。</p>
        <h4><span></span>发起单位概况</h4>
        <h5>（一）陕西零工保信息科技有限公司。</h5>
        <p>
          陕西零工保信息科技有限公司于2019年3月成立于中国（陕西）自由贸易试验区秦汉新城功能区，是专注开发服务于全球自由职业者的互联网平台。公司创建的“全球云端”零工创客共享服务平台建设零工保大数据服务中心，为全球自由职业者实现在互联上0成本创业，并将其服务产品化，从而为企业提供个性多元的“服务产品”。</p>
        <h5>（二）中译语通科技（陕西）有限公司。</h5>
        <p>
          中译语通科技（陕西）有限公司作为中宣部管理的文化央企—中国出版集团成员企业，由中译语通科技股份有限公司与秦汉新城开发建设集团共同出资成立，是陕西省“一带一路”语言服务及大数据平台建设运营单位，是全球领先的大数据与人工智能企业。总公司成立于1973年，前身是“联合国资料小组”，目前也是国内第一家翻译公司和联合国在中国指定的唯一语言服务供应商。2017年1月25日，中译语通陕西公司正式落户西咸新区秦汉新城，迅速成长为中国西部地区语言服务和大数据的龙头企业，以及陕西省落实国家“一带一路”倡议的中坚力量。</p>
        <h5>（三）西安国联质量检测技术股份有限公司。</h5>
        <p>
          西安国联质量检测技术股份有限公司成立于2011年12月,是第三方检测机构，拥有CMA中国计量认证，CMAF食品检验机构资质认定；可实现质量检验、进出口商检、未知物测试、技术诊断、工艺分析、产品鉴定、项目技术论证、现场监测、远程监控和新技术研究的综合性机构。公司目前拥有1.7万平米实验室，专利技术及百余个，参与国际国内能力验证千余次，检测覆盖33个大类，与世界600家检测机构达成技术合作共享，检验结果得到全球74个国家公认，拥有先进权威的食品药品毒理病理功能安全评价中心，科研创新绿色植被环保项目已申报，受到国内各主流媒体及环保主管高度关注，在业界互联网+质量检测的商业模式实现了国内全领域全区域全天候无缝法定检测服务。多年的沉淀以及不断的创新，让国联质检在检测行业赢得了尊重，2016年5月成功登陆新三板（股票代码837554），并以此为契机，依托资本市场的优势，加快企业发展步伐，增强企业综合实力和核心竞争力。</p>
        <h5>（四）陕西龙图文化科技有限公司。</h5>
        <p>
          陕西龙图文化科技有限公司成立于2016年6月，位于西咸新区沣西新城西部云谷E3-4层，主要专注AR/VR、数字文创等领域的内容制作与研发，是中国增强现实产业联盟会员单位、西咸新区文化产业协会的副秘书长单位，京东AR技术应用西北唯一合作单位。2017年9月成立人力资源服务中心，专注于游戏动漫、文化创意类人才服务，为互联网、IT、游戏动漫领域公司提供优质人才，为企业输送新鲜血液，助力企业健康成长。2017年12月，公司与沣西新城文广新局共同打造了西部数字文化产业基地，主要服务于科技与文化类企业，注重科技与文化融合发展，为企业搭建资金、人力等各类公共平台，加强资源整合、信息共享，生态化地连接更多元的科技与文化主体，让科技与文化相互赋能，推动数字文化产业快速发展，为“文化自信”做出努力。</p>
        <h5>（五）陕西佰美基因股份有限公司。</h5>
        <p>
          陕西佰美基因股份有限公司成立于2001年，是一家专注于精准医疗技术开发及应用转化的国家级高新技术企业。2002年与西北大学共同发起成立国家微检测系统工程技术研究中心并获科技部批准，是全国三大基因检测工程技术中心之一。佰美基因根植精准医疗领域，承担国家863计划项目、“十三五”重点研发计划项目等三十余项国家级科研项目，累计获得国家科研经费近2亿元；公司核心技术获得授权专利46项，其中国内发明专利40项，国防专利5项，美国专利1项；软件著作权7项；公司和西北大学合作完成的“药物个性化筛选和精准医疗研究关键技术研究”项目获得2017年陕西省科技进步一等奖。公司受西安市人民政府委托，连续承办三届全球硬科技创新大会国际生物技术论坛，推动了行业的交流和发展。2020年新型冠状病毒感染的肺炎疫情发生后，作为省内为数不多的几家三类体外诊断试剂研发和生产企业之一，公司紧急研发了检测试剂：新型冠状病毒核酸检测试剂盒（荧光PCR法），并承诺向社会无偿捐赠10万人份，将有助于快速筛查和监测感染人群。</p>
        <h5>（六）陕西新昆互动文化科技有限公司。</h5>
        <p>
          陕西新昆互动文化科技有限公司是民营文化企业，成立于2018年6月，注册资金500万，位于西咸新区沣东新城文化自贸区，是一家专门从事互联网+文物教育及应用软件开发的文化科技公司。公司前身是西安新昆信息科技公司，2018年迁入沣东新城。公司之所以这么做，看重的是西咸新区沣东新城丰富的历史文化资源，及作为国家级新区和自贸区无可比拟的政策优势。公司成立以来，经济效益社会效益不断彰显，2018年纳入规模以上文化企业。新昆公司拥有丰富的技术人才资源和技术研发基础，具有很强的科研实力，在AR/VR技术、数字版权保护技术等方面有较大的技术突破，公司现已取得十三项软件著作权。</p>
        <h5>（七）西安奥卡云数据科技有限公司。</h5>
        <p>西安奥卡云数据科技有限公司坐落于历史文化浓厚、环境优美的西安西咸新区秦汉新城，注册资金人民币1.1亿元。
          奥卡云数据是专注于企业级存储、数据平台的专业数据服务厂商，依托有着深厚专业素养和强大创新能力的技术团队致力于为行业用户打造融合、智能、高效、可靠、完全自主可控的企业级产品及相关解决方案。奥卡云通过国内外权威标准的合规认证，共获取了64项发明专利认证，21项软件著作认证，为了用户的安全性和合规性保证努力前行。</p>
        <h5>（八）西咸新区茯茶镇文化产业集团有限公司。</h5>
        <p>
          西咸新区茯茶镇文化产业集团有限公司成立于2014年3月20日，注册资本1亿元人民币，现已发展成为一家集茯茶文化产业园开发与运营、房地产开发与经营、茯茶产品加工销售、物业管理、商标代理及咨询服务等业务于一体的多元化、现代化企业。茯茶镇集团按照“政府引导、产业主导、社会投资、市场运作”的经营方针，进行茯茶镇项目的投资、策划、规划、建设及招商等工作，通过全资、绝对控股、相对控股、参股等股权合作方式，扩大企业规模，打造专业化子公司，将茯茶公司发展成为投资、融资的资本运作平台；整合各专业子公司资源，对外承接综合性产业园区项目，加快企业快速成长的。全力打造中国茯茶文化产业特色小镇的目标，全面推动茯茶产业的发展以及茯茶镇的开发建设。公司陆续研发了24款茯茶产品和4款配套煮茶器，推出的“泾新茯茶”、“泾新丝路茯茶”系列产品深受广大消费者的喜爱，并荣获第二十一届中国农业高新科技成果博览会“后稷特别奖”，泾新茯茶店被咸阳市商务局评为“百优示范店”。</p>
        <h5>（九）陕西乐华欢乐世界文化发展有限公司。</h5>
        <p>
          陕西乐华欢乐世界文化发展有限公司是陕西乐华恒业投资（集团）股份有限公司旗下全资子公司，承担着集团文化产业业务的开发和运营任务，是集团“欢乐文化”品牌的先锋和“乐华城模式”的核心。作为国内首屈一指的文化产业项目运营实体单位，陕西乐华欢乐世界文化发展有限公司始终关注各个年龄层游客（市民）的娱乐诉求，致力于创新发展欢乐文化多级维度，为游客提供可参与、全方位的欢乐体验。目前，陕西乐华欢乐世界文化发展有限公司已经形成集创意、研发、生产、销售为一体的文化产业链模型。</p>
        <h5>（十） 西咸新区长安港跨境电商物流有限公司。</h5>
        <p>
          西咸新区长安港跨境电商物流有限公司是着力打造国际物流（跨境电商、国际快件、保税物流）、物流地产（园区开发、标准仓建设）、海外拓展（海外仓）、供应链金融等国际物流业务经营的的现代物流服务企业，致力打造陕西跨境电商国际快件产业园。陕西跨境电商国际快件产业园依托陕西省“一带一路”中心区域的区位优势，充分发挥长安港在国际寄递物流及跨境电商领域的市场资源及运营能力优势，项目面向国际快递企业、跨境电商企业及产业链相关上下游企业。在全国首创建设海关集中查验中心，同时通过开发陕西跨境电商国际快件综合服务平台和跨境寄递物流大数据风险防控系统，最大限度的提升通关监管信息化水平和风险防控水平。长安港致力构建“跨境寄递物流供应链体系”，服务于我国自主的国际快件航空运输网络，服务于航空物流的发展建设、服务于跨境电商“买全球、卖全球”的重要对外通道，从而服务西安国际航空枢纽的发展。</p>
        <p>
          拟发展会员包括单位会员和个人会员，主要为西咸新区境内各行业热衷于公益事业的单位和个人。其中团体发起人为10家单位会员，其他会员主要分布在西咸新区所属各新城，包括空港新城、沣东新城、秦汉新城、沣西新城、泾河新城，共计5个新城，满足覆盖新区范围内4个以上新城要求。协会获批后，未来还将吸纳更多省内从事服务贸易业务的企业。</p>

        <P>上述发起人对本社会组织登记材料的合法性、真实性、准确性、有效性、完整性负责，对社会组织登记之前的活动负责。</P>
      </div>
      <div slot="rightDiv">
        <card class="mouseStyle" ref="card" list-style="none"></card>
        <card ref="card1"></card>
      </div>
    </left-and-right>
  </div>
</template>

<script>
import leftAndRight from "@/components/public/leftAndRight";
import card from "@/components/public/card";

export default {
  name: "introductionAssociation",
  data() {
    return {
      nav: {
        parent: '协会概况',
        now: '协会简介',
        children: [
          {index: 0, name: '协会简介', to: '/introductionAssociation'},
          {index: 1, name: '协会章程', to: '/constitution'},
          {index: 2, name: '组织架构', to: '/organizationalStructure'},
          {index: 3, name: '入会申请', to: '/applicationMembership'}
        ]
      },
      card0: [
        {
          id: 0,
          title: '协会愿景',
          flag:true,
          content: [
            {
              id: 0,
              title: '服务共享，聚智共赢'
            }
          ]
        },
        {
          id: 1,
          title: '协会使命',
          flag:true,
          content: [
            {
              id: 0,
              title: '为会员创造价值，推动行业自律'
            }
          ]
        }
      ],
      card1: [
        {
          index: 0,
          title: '重要通知',
        }
      ]
    }
  },
  components: {
    leftAndRight,
    card
  },
  created() {
    this.$nextTick(()=>{
      this.$refs.card.init(this.card0)
      this.getData()
    })
  },
  methods:{
    getData(){
      const ths=this
      this.$http.get('/safe/getIndexInformation', {
        params: {
          name: '重要通知',
        }
      }).then((res) => {
        let forms=res.data

        for(let i in forms){
          let pic = forms[i].pic
          if (pic != null) {
            forms[i].pic = this.$constContent.imgUrl + pic
          }
          let time = forms[i].time;
          let date = new Date(time);
          res.data[i].time=this.$constContent.dateFormat("YYYY-mm-dd HH:MM",date)
        }
        this.card1[0].content= res.data
        ths.$nextTick(()=>{
          ths.$refs.card1.init(this.card1)
        })
      })
    }
  }
}
</script>

<style scoped >

.mouseStyle >>> .information ul li p{
  cursor: default !important;
}
h4, h5 {
  margin: 10px 0;
}

p {
  color: #666666;
  font-size: 14px;
  /*text-indent: 2em;*/
}

.slogan {
  width: 80%;
  height: 96px;
  background-image: url("../../assets/image/background01.png");
  background-size: 100% 100%;
  padding: 42px 10%;
}
span{
  width: 4px;
  height: 15px;
  margin-right: 5px;
  display: inline-block;
  background-color: red;
}

.slogan h4 {
  margin: 0 auto;
  font-size: 28px;
  font-weight: 400;
}
</style>